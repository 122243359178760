import React from "react";
import styles from "./Services.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "./Services.css";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

const Services = () => {
  return (
    <div className={styles.main}>
      <Container>
        <div className={styles.cont}>
          <h2 className="h2_main text-center pb-2">EXPLORE</h2>
          <h1 className="h1_main text-center pb-5">OUR CATEGORIES</h1>
          <Row className="gy-4">
            <Col lg={3}>
              <Link style={{ textDecoration: "none" }} to="/shop/12V-Batteries-Trolling-and-RV">
                <div className={styles.box}>
                  <img alt="" src="/images/category1.webp" />
                  <div className={styles.contentCol}>
                    <h2 className="h2_main">Lithium Batteries</h2>
                    <p className="para_main">
                      12V, 24V, 36V and 48V LITHIUM LiFePO4 BATTERIES by DAKOTA
                      LITHIUM, VOLTHIUM and AOLITHIUM
                    </p>
                  </div>
                </div>
              </Link>
            </Col>
            <Col lg={3}>
              <Link
                style={{ textDecoration: "none" }}
                to="/shop/Ecoflow-Portable-Solar-Panels"
              >
                <div className={styles.box}>
                  <img alt="" src="/images/category2.webp" />
                  <div className={styles.contentCol}>
                    <h2 className="h2_main">Portable Power Stations</h2>
                    <p className="para_main">
                      ECOFLOW RIVER AND ECOFLOW DELTA LITHIUM LiFePO4 POWER
                      STATIONS/SOLAR GENERATORS
                    </p>
                  </div>
                </div>
              </Link>
            </Col>
            <Col lg={3}>
              <Link
                style={{ textDecoration: "none" }}
                to="/shop/Off-Grid-and-Home-Solar-Kits"
              >
                <div className={styles.box}>
                  <img alt="" src="/images/category3.webp" />
                  <div className={styles.contentCol}>
                    <h2 className="h2_main">SOLAR KITS</h2>
                    <p className="para_main">
                      ECOFLOW RIVER AND ECOFLOW DELTA LITHIUM LiFePO4 with SOLAR
                      PANELS - KITS
                    </p>
                  </div>
                </div>
              </Link>
            </Col>
            <Col lg={3}>
              <Link
                style={{ textDecoration: "none" }}
                to="/shop/Solar-Panels-400-to-580-Watt"
              >
                <div className={styles.box}>
                  <img alt="" src="/images/category4.webp" />
                  <div className={styles.contentCol}>
                    <h2 className="h2_main">
                      SOLAR & ENERGY STORAGE, SOLAR SYSTEMS
                    </h2>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Services;
