import React, { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen/HomeScreen";
import ProductScreen from "./screens/OrderingScreens/ProductScreen";
import CartScreen from "./screens/OrderingScreens/CartScreen";
import LoginScreen from "./screens/User Screens/LoginScreen";
import RegisterScreen from "./screens/User Screens/RegisterScreen";
import ProfileScreen from "./screens/User Screens/ProfileScreen";
import ShippingScreen from "./screens/OrderingScreens/ShippingScreen";
import PaymentScreen from "./screens/OrderingScreens/PaymentScreen";
import PlaceOrderScreen from "./screens/OrderingScreens/PlaceOrderScreen";
import OrderScreen from "../src/screens/OrderingScreens/OrderScreen";
import UserListScreen from "./screens/Admin Screens/UserListScreen";
import UserEditScreen from "./screens/Admin Screens/UserEditScreen";
import ProductListScreen from "./screens/Admin Screens/ProductListScreen";
import ProductEditScreen from "./screens/Admin Screens/ProductEditScreen";
import OrderListScreen from "./screens/Admin Screens/OrderListScreen";
import MyOrdersScreen from "./screens/User Screens/MyOrdersScreen";
import ShopScreen from "./screens/ShopScreen/ShopScreen";
import ForgotPasswordScreen from "./screens/User Screens/ForgotPasswordScreen";
import AboutScreen from "./screens/InfoScreens/AboutScreen";
import PrivacyPolicyScreen from "./screens/InfoScreens/PrivacyPolicyScreen";
import TermsScreen from "./screens/InfoScreens/TermsScreen";
import ContactScreen from "./screens/InfoScreens/ContactScreen";
import ReturnPolicyScreen from "./screens/InfoScreens/ReturnPolicyScreen";
import ScrollToTop from "./components/Scroll To Top/ScrollToTop";
import SolarAndWindPower from "./screens/InfoScreens/SolarAndWindPower";
import BatteryTechnology from "./screens/InfoScreens/BatteryTechnology";
import Commercial from "./screens/InfoScreens/Commercial";
import Partners from "./screens/InfoScreens/Partners";
import BlogsScreen from "./screens/BlogsScreens/BlogsScreen";
import Postdetails from "./components/Blogs & Landing Page/Postdetails";
import Blog from "./screens/Blog/Blog";
import ThankYou from "./screens/InfoScreens/ThankYou";
import CategoryListScreen from "./screens/Admin Screens/CategoryListScreen";
import CategoryEditScreen from "./screens/Admin Screens/CategoryEditScreen";

function App() {
  return (
    <Fragment>
      <ScrollToTop>
        <Routes>
          <Route path="/*" element={<Navigate to="/" />} />
          <Route path="/" element={<HomeScreen />} />
          <Route path="/home" element={<HomeScreen />} />
          <Route path="/about" element={<AboutScreen />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="/shop" element={<ShopScreen />} />
          <Route path="/shop/:category" element={<ShopScreen />} />
          <Route path="/shop/search/:keyword" element={<ShopScreen />} />
          <Route path="/solar-installation" element={<SolarAndWindPower />} />
          <Route path="/battery-technology" element={<BatteryTechnology />} />
          <Route path="/commercial" element={<Commercial />} />
          <Route
            path="/warranty-and-return"
            element={<PrivacyPolicyScreen />}
          />
          <Route path="/privacy-policy" element={<ReturnPolicyScreen />} />
          <Route path="/terms" element={<TermsScreen />} />
          {/* <Route path="/return-policy" element={<ReturnPolicyScreen />} /> */}
          <Route path="/contact" element={<ContactScreen />} />
          <Route path="/thank-you" element={<ThankYou />} />
          <Route path="/product/:id" element={<ProductScreen />} />
          <Route path="/cart" element={<CartScreen />} />
          <Route path="/cart/:id" element={<CartScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
          <Route path="/myorders" element={<MyOrdersScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/accountrecovery" element={<ForgotPasswordScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/shipping" element={<ShippingScreen />} />
          <Route path="/payment" element={<PaymentScreen />} />
          <Route path="/placeorder" element={<PlaceOrderScreen />} />
          <Route path="/order/:id" element={<OrderScreen />} />
          <Route path="/admin/userlist" element={<UserListScreen />} />
          <Route path="/admin/user/:id/edit" element={<UserEditScreen />} />
          <Route path="/admin/productlist" element={<ProductListScreen />} />
          <Route
            path="/admin/product/:id/edit"
            element={<ProductEditScreen />}
          />
          <Route path="/admin/categorylist" element={<CategoryListScreen />} />
          <Route
            path="/admin/category/:id/edit"
            element={<CategoryEditScreen />}
          />
          <Route path="/admin/orderlist" element={<OrderListScreen />} />
          <Route path="/admin/blogs" element={<BlogsScreen />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blogs/:id" element={<Postdetails />} />
        </Routes>
      </ScrollToTop>
    </Fragment>
  );
}

export default App;
