import React, { Fragment, useEffect, useState } from "react";
import { Form, Button, Col, Row, Container, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import { listProducts } from "../../actions/productActions";
import {
  getCategoryDetails,
  updateCategory,
} from "../../actions/categoryActions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Header from "../../components/Header_/Header";

const CategoryEditScreen = () => {
  const { id: categoryId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);

  const categoryDetails = useSelector((state) => state.categoryDetails);
  const { loading, error, category } = categoryDetails;

  const productList = useSelector((state) => state.productList);
  const {
    loading: loadingProducts,
    error: errorProducts,
    products,
  } = productList;

  const categoryUpdate = useSelector((state) => state.categoryUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = categoryUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: "CATEGORY_UPDATE_RESET" });
    if (!userInfo || !userInfo.isAdmin) {
      navigate("/login");
    }
    if (successUpdate) {
      navigate("/admin/categorylist");
      window.location.reload();
    } else {
      if (!category || category._id !== categoryId) {
        dispatch(getCategoryDetails(categoryId));
        dispatch(listProducts());
      } else {
        setName(category.name);
        setSelectedProducts(category.products || []);
      }
    }
  }, [dispatch, navigate, category, categoryId, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateCategory(categoryId, { name, products: selectedProducts }));
  };

  // Handle product selection (add to selected list)
  const handleProductSelect = (product) => {
    if (!selectedProducts.find((p) => p._id === product._id)) {
      setSelectedProducts([...selectedProducts, product]);
    }
  };

  // Handle removing a product from selected list
  const handleProductRemove = (productId) => {
    setSelectedProducts(selectedProducts.filter((p) => p._id !== productId));
  };

  // Handle drag-and-drop sorting in selected products list
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedProducts = Array.from(selectedProducts);
    const [movedItem] = reorderedProducts.splice(result.source.index, 1);
    reorderedProducts.splice(result.destination.index, 0, movedItem);
    setSelectedProducts(reorderedProducts);
  };

  return (
    <>
      <Header />
      <Fragment>
        <div className="main">
          <Container>
            <Link to="/admin/categorylist" className="btn btn-dark my-3">
              Go Back
            </Link>
            <Row className="justify-content-md-center">
              <Col xs={12} md={8}>
                <h1>Edit Category</h1>
                {loadingUpdate && <Loader />}
                {errorUpdate && (
                  <Message variant="danger">{errorUpdate}</Message>
                )}
                {loading ? (
                  <Loader />
                ) : error ? (
                  <Message variant="danger">{error}</Message>
                ) : (
                  <Form onSubmit={submitHandler}>
                    <Form.Group controlId="name">
                      <Form.Label>Category Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter category name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Form.Group>

                    <Row className="mt-5">
                      {/* Available Products List */}
                      <Col md={6}>
                        <h5>Available Products</h5>
                        {loadingProducts ? (
                          <Loader />
                        ) : errorProducts ? (
                          <Message variant="danger">{errorProducts}</Message>
                        ) : (
                          <div className="product-list">
                            {products
                              .filter(
                                (product) =>
                                  !selectedProducts.some(
                                    (p) => p._id === product._id
                                  )
                              ) // Filter out selected products
                              .map((product) => (
                                <div
                                  key={product._id}
                                  className="product-item d-flex align-items-center p-2 border mb-2"
                                >
                                  <Image
                                    src={product.image}
                                    alt={product.name}
                                    width={50}
                                    height={50}
                                    className="me-2"
                                  />
                                  <span className="flex-grow-1">
                                    {product.name}
                                  </span>
                                  <Button
                                    variant="success"
                                    size="sm"
                                    onClick={() => handleProductSelect(product)}
                                  >
                                    +
                                  </Button>
                                </div>
                              ))}
                          </div>
                        )}
                      </Col>

                      {/* Selected Products List (Draggable) */}
                      <Col md={6}>
                        <h5>Selected Products</h5>
                        <DragDropContext onDragEnd={handleDragEnd}>
                          <Droppable droppableId="selectedProducts">
                            {(provided) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="selected-product-list"
                              >
                                {selectedProducts.map((product, index) => (
                                  <Draggable
                                    key={product._id}
                                    draggableId={product._id}
                                    index={index}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className="product-item d-flex align-items-center p-2 border mb-2 bg-light"
                                      >
                                        <Image
                                          src={product.image}
                                          alt={product.name}
                                          width={50}
                                          height={50}
                                          className="me-2"
                                        />
                                        <span className="flex-grow-1">
                                          {product.name}
                                        </span>
                                        <Button
                                          variant="danger"
                                          size="sm"
                                          onClick={() =>
                                            handleProductRemove(product._id)
                                          }
                                        >
                                          &times;
                                        </Button>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Col>
                    </Row>

                    <Button type="submit" variant="primary" className="mt-3">
                      Update Category
                    </Button>
                  </Form>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </Fragment>
    </>
  );
};

export default CategoryEditScreen;
