import axios from 'axios';

export const listCategories = () => async (dispatch) => {
    try {
        dispatch({ type: 'CATEGORY_LIST_REQUEST' });
        const { data } = await axios.get('/api/categories');
        dispatch({ type: 'CATEGORY_LIST_SUCCESS', payload: data });
    } catch (error) {
        dispatch({
            type: 'CATEGORY_LIST_FAIL',
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
};

export const getCategoryDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: 'CATEGORY_DETAILS_REQUEST' });
        const { data } = await axios.get(`/api/categories/${id}`);
        dispatch({ type: 'CATEGORY_DETAILS_SUCCESS', payload: data });
    } catch (error) {
        dispatch({
            type: 'CATEGORY_DETAILS_FAIL',
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
};

export const getCategoryDetailsByName = (name) => async (dispatch) => {
    try {
        dispatch({ type: 'CATEGORY_DETAILS_BY_NAME_REQUEST' });
        const { data } = await axios.get(`/api/categories/name/${name}`);
        dispatch({ type: 'CATEGORY_DETAILS_BY_NAME_SUCCESS', payload: data });
    } catch (error) {
        dispatch({
            type: 'CATEGORY_DETAILS_BY_NAME_FAIL',
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
};

export const createCategory = () => async (dispatch, getState) => {
    try {
        dispatch({ type: "CATEGORY_CREATE_REQUEST" });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        const { data } = await axios.post(`/api/categories`, {}, config);

        dispatch({
            type: "CATEGORY_CREATE_SUCCESS",
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: "CATEGORY_CREATE_FAIL",
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

export const deleteCategory = (id) => async (dispatch, getState) => {
    try {
        dispatch({ type: "CATEGORY_DELETE_REQUEST" });

        const { userLogin: { userInfo } } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${userInfo.token}`,
            },
        };

        await axios.delete(`/api/categories/${id}`, config);

        dispatch({ type: "CATEGORY_DELETE_SUCCESS" });
    } catch (error) {
        dispatch({
            type: "CATEGORY_DELETE_FAIL",
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
};

export const updateCategory = (categoryId, categoryData) => async (dispatch, getState) => {
    try {
        dispatch({ type: 'CATEGORY_UPDATE_REQUEST' });

        const { userLogin: { userInfo } } = getState();
        const config = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        };

        const { data } = await axios.put(`/api/categories/${categoryId}`, categoryData, config);

        dispatch({ type: 'CATEGORY_UPDATE_SUCCESS', payload: data });
    } catch (error) {
        dispatch({
            type: 'CATEGORY_UPDATE_FAIL',
            payload: error.response && error.response.data.message ? error.response.data.message : error.message
        });
    }
};
