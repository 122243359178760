import React, { useEffect, useRef } from "react";
import Footer from "../../components/Footer_/Footer";
import CommonHero from "../../components/CommonHero_/CommonHero";
import DynamicProducts from "../../components/DynamicProducts/DynamicProducts";
import { useParams } from "react-router-dom";
import Reviews from "../../components/Reviews_/Reviews";
import { Helmet } from "react-helmet";

const ShopScreen = () => {
  const myRef = useRef(null);
  const params = useParams();
  const query = params.keyword;
  const category = params.category;

  const executeScroll = () => myRef.current.scrollIntoView();

  useEffect(() => {
    if (query) {
      executeScroll();
    }
  }, [query]);

  return (
    <>
      <Helmet>
        <title>
          {!category
            ? `Top-Selling Dakota Lithium & EcoFlow Batteries | TRU Off Grid`
            : `${category} at TRU Off Grid | Free Shipping Across Canada`}
        </title>
        <meta name="keywords" content="Batteries in Ontario" />
        <link rel="canonical" href="https://www.truoffgrid.com/" />
        <meta
          name="description"
          content="Shop top-selling Dakota Lithium & EcoFlow Batteries and much more. Enjoy an 11-year warranty, free charger, and free shipping Canada-wide."
        />
      </Helmet>
      <CommonHero
        shopHero={true}
        image="/images/hero-shop.jpg"
        heading="Our Shop"
      />
      <DynamicProducts Ref={myRef} />
      <Reviews />
      <Footer newsletter={true} />
    </>
  );
};

export default ShopScreen;
