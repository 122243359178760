import React, { Fragment, useEffect } from "react";
import { Button, Table, Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
  listCategories,
  deleteCategory,
  createCategory,
} from "../../actions/categoryActions";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import { LinkContainer } from "react-router-bootstrap";

const CategoryListScreen = () => {
  const dispatch = useDispatch();
  const history = useNavigate();

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  const categoryDelete = useSelector((state) => state.categoryDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = categoryDelete;

  const categoryCreate = useSelector((state) => state.categoryCreate);
  const {
    category: createdCategory,
    success: successCreate,
    loading: loadingCreate,
    error: errorCreate,
  } = categoryCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: "CATEGORY_CREATE_RESET" });

    if (!userInfo || !userInfo.isAdmin) {
      history("/login");
    }

    if (successCreate) {
      history(`/admin/category/${createdCategory._id}/edit`);
    } else {
      dispatch(listCategories());
    }
  }, [dispatch, history, userInfo, successDelete, successCreate, createdCategory]);

  const deleteHandler = (id) => {
    if (window.confirm("Are You Sure You Want To Delete This Category?")) {
      dispatch(deleteCategory(id));
    }
  };

  const createCategoryHandler = () => {
    dispatch(createCategory());
  };

  return (
    <>
      <Header />
      <Fragment>
        <div className="main">
          <Container>
            <Row className="align-items-center">
              <Col>
                <h1>Categories</h1>
              </Col>
              <Col className="d-flex justify-content-end align-items-end">
                <Button
                  style={{ alignSelf: "flex-end" }}
                  className="my-3"
                  onClick={createCategoryHandler}
                >
                  <i className="fas fa-plus"></i> Create Category
                </Button>
              </Col>
            </Row>
            {loadingDelete && <Loader />}
            {errorDelete && <Message variant="danger">{errorDelete}</Message>}
            {loadingCreate && <Loader />}
            {errorCreate && <Message variant="danger">{errorCreate}</Message>}
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant="danger">{error}</Message>
            ) : (
              <Table striped bordered hover responsive className="table-sm">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>NAME</th>
                    <th>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {categories.map((category) => (
                    <tr key={category._id}>
                      <td>{category._id}</td>
                      <td>{category.name}</td>
                      <td>
                      <LinkContainer to={`/admin/category/${category._id}/edit`}>
                        <Button variant="light" className="btn-sm">
                          <i className="fas fa-edit"></i>
                        </Button>
                      </LinkContainer>
                        <Button
                          variant="danger"
                          className="btn-sm ms-2"
                          onClick={() => deleteHandler(category._id)}
                        >
                          <i className="fas fa-trash"></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Container>
        </div>
      </Fragment>
      <Footer />
    </>
  );
};

export default CategoryListScreen;
