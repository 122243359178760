import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import ProductsLoader from "../../components/ProductLoader/ProductsLoader";
import styles from "./DynamicProducts.module.css";
import "./DynamicProducts.css";
import { useParams } from "react-router-dom";
import IdvProduct from "../IdvProduct_/IdvProduct";
import Loader from "../Loader";
import {
  listCategories,
  getCategoryDetailsByName,
} from "../../actions/categoryActions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

const DynamicProducts = ({ Ref }) => {
  const params = useParams();
  const dispatch = useDispatch();

  const categoryList = useSelector((state) => state.categoryList);
  const {
    categories,
    loading: categoriesLoading,
    error: categoriesError,
  } = categoryList;

  const categoryDetailsByName = useSelector(
    (state) => state.categoryDetailsByName
  );
  const { categoryByName, loadingByName, errorByName } = categoryDetailsByName;

  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // Extract category and keyword from URL
  const { category: categoryParam, keyword } = params;

  useEffect(() => {
    dispatch(listCategories());
  }, [dispatch]);

  useEffect(() => {
    if (categories && categories.length > 0) {
      if (categoryParam) {
        setSelectedCategory(categoryParam.replaceAll("-", "%20"));
      } else {
        setSelectedCategory(categories[0].name);
      }

      if (keyword) {
        setSearchTerm(keyword);
      }
    }
  }, [categories, categoryParam, keyword]);

  useEffect(() => {
    if (selectedCategory) {
      dispatch(getCategoryDetailsByName(selectedCategory.replaceAll("-", "%20")));
    }
  }, [selectedCategory, dispatch]);

  const handleCategoryChange = (categoryName) => {
    setSelectedCategory(categoryName);
  };

  const querySubmitHandler = (e) => {
    e.preventDefault();
  };

  console.log(selectedCategory)

  return (
    <Fragment>
      <div ref={Ref} className={`${styles.main} `}>
        <Container>
          <Row className={styles.filter}>
            <div className={styles.search}>
              <form
                onSubmit={querySubmitHandler}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <input
                  maxLength="24"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  type="text"
                  placeholder="Search"
                />{" "}
                <span>
                  <img
                    alt=""
                    onClick={querySubmitHandler}
                    type="submit"
                    src="/images/search.svg"
                  />
                </span>
              </form>
            </div>
            <div className={styles.gender}>
              <select onChange={(e) => handleCategoryChange(e.target.value)}>
                <option disabled value="">
                  Select Category
                </option>
                {categories?.map((cat) => (
                  <option
                    key={cat._id}
                    value={cat.name}
                    selected={selectedCategory === cat.name}
                  >
                    {cat.name}
                  </option>
                ))}
              </select>
            </div>
          </Row>

          <Row className="gy-5 pt-5">
            <Col lg={4}>
              <div className={styles.categories}>
                <h5 className="h5_main">Category</h5>
                <div className={styles.mobList}>
                  {categoriesLoading ? (
                    <span>
                      <Loader width="50px" height="50px" />
                    </span>
                  ) : categoriesError ? (
                    <Message variant="danger">{categoriesError}</Message>
                  ) : (
                    <Swiper
                      pagination={{
                        dynamicBullets: true,
                        clickable: true,
                      }}
                      slidesPerView={1}
                      modules={[Pagination]}
                      className={styles.swiper}
                    >
                      {categories?.map((category) => (
                        <SwiperSlide className={styles.swiperSlide}>
                          <div
                            className={
                              selectedCategory.replaceAll("%20", " ") === category.name
                                ? `${styles.boxActive} ${styles.box}`
                                : styles.box
                            }
                            onClick={() => handleCategoryChange(category.name)}
                          >
                            <h6>{category.name}</h6>
                            <span>(0{category?.products.length})</span>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
                </div>
                <div className={styles.list}>
                  {categoriesLoading ? (
                    <span>
                      <Loader width="50px" height="50px" />
                    </span>
                  ) : categoriesError ? (
                    <Message variant="danger">{categoriesError}</Message>
                  ) : (
                    categories?.map((category) => (
                      <div
                        className={
                          selectedCategory.replaceAll("%20", " ") === category.name
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => handleCategoryChange(category.name)}
                      >
                        <h6>{category.name}</h6>
                        <span>(0{category?.products.length})</span>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </Col>
            <Col md={12} lg={8}>
              {loadingByName || categoriesLoading ? (
                <ProductsLoader length={6} />
              ) : errorByName ? (
                <Message variant="danger">{errorByName}</Message>
              ) : !categoryByName ||
                !categoryByName.products ||
                categoryByName.products.length === 0 ? (
                <h2
                  style={{
                    fontStyle: "normal",
                    textTransform: "none",
                    color: "red",
                  }}
                  className="h2_main pt-5 text-center"
                >
                  NO MATCHING ITEMS
                </h2>
              ) : (
                <Row className="gx-lg-5 gy-5">
                  {categoryByName?.products
                    ?.filter((product) =>
                      product.name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    )
                    .map((product) => (
                      <Col
                        style={{ marginBottom: "35px", height: "auto" }}
                        key={product._id}
                        sm={12}
                        lg={6}
                      >
                        <IdvProduct productPage={true} product={product} />
                      </Col>
                    ))}
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default DynamicProducts;
